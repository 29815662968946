import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                                <HashLink smooth to="/#hero">
                                    <img
                                        src="https://i.postimg.cc/Wbb9HzF1/Untitled-design-4-removebg-preview.png"
                                        alt="gulf Logo"
                                        className="h-12 mx-auto mb-4"
                                    />
                                </HashLink>
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>Level 2 , Innovation One Tower , DIFC , Dubai UAE</h5>
                                    <p>Tel: +971 565672642</p>
                                    <p>info@gulfstack.com,</p>
                                </div>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-xl mb-6">
                                Social Media Links.
                            </div>
                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <a
                                            href="https://www.instagram.com/gulfstack/"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 icon-bg hover:bg-white-100 shadow transition duration-150 ease-in-out"
                                            aria-label="Instagram"
                                            target="_blank"
                                            rel="noopener noreferrer" // Security best practices for external links
                                        >
                                            <i className="fa fa-instagram" style={{ fontSize: '36px' , color : "#e1306c" }}></i>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                    <a
                                            href="https://www.instagram.com/gulfstack/"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 icon-bg hover:bg-white-100 shadow transition duration-150 ease-in-out"
                                            aria-label="Instagram"
                                            target="_blank"
                                            rel="noopener noreferrer" // Security best practices for external links
                                        >
                                            <i className="fa fa-facebook" style={{ fontSize: '36px' , color : "blue" }}></i>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                    <a
                                            href="https://www.linkedin.com/company/gulf-stack/"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 icon-bg hover:bg-white-100 shadow transition duration-150 ease-in-out"
                                            aria-label="Linkedin"
                                            target="_blank"
                                            rel="noopener noreferrer" // Security best practices for external links
                                        >
                                         <i className="fa fa-linkedin" style={{ fontSize: '36px' , color : "rgb(0 74 172)" }}></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4 whitespace-nowrap">
                        <div className="w-full md:w-4/12 px-9 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()} <HashLink to="#" className="hover:text-gray-900">GULF STACK LLC</HashLink>. All rights reserved.
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    );
}

export default Footer;
