import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import { CheckIcon } from '@heroicons/react/24/solid';

const links = [
  { name: 'Collaboration', href: '#' },
  { name: 'CRM', href: '#' },
  { name: 'Tasks & Projects', href: '#' },
  { name: 'Sites & Stores', href: '#' },
  { name: 'HR & Automation', href: '#' },
];

const stats = [
  { name: 'Organizations Chosen', value: '15,000,000' },
  { name: 'Available Languages', value: '18' },
  { name: 'no per-user pricing', value: '100% flat fee' },
  { name: 'Lead conversion rate', value: '+43%' },
];

const cloudPricingPlans = [
  {
    name: 'Basic Plan',
    price: '225 AED',
    description: 'Effective collaboration for small businesses and sales teams',
    users: '5 Users | 24GB',
    features: ['COLLABORATION', 'TASK & PROJECTS', 'CRM', 'DRIVE'],
  },
  {
    name: 'Standard Package',
    price: '455 AED',
    description: 'Advanced collaboration for your entire company and workgroups',
    users: '50 Users | 100GB',
    features: [
      'COLLABORATION',
      'TASK & PROJECTS',
      'CRM',
      'DRIVE',
      'CONTACT CENTER',
      'WEBSITE BUILDER',
      'ONLINE STORE',
      'MARKETING',
      'ONLINE DOCUMENTS',
    ],
  },
  {
    name: 'Professional',
    price: '915 AED',
    description: 'Maximum sales and business process automation',
    users: '100 Users | 1,024GB',
    features: [
      'COLLABORATION',
      'TASK & PROJECTS',
      'CRM',
      'DRIVE',
      'CONTACT CENTER',
      'WEBSITE BUILDER',
      'ONLINE STORE',
      'MARKETING',
      'ONLINE DOCUMENTS',
      'SALES INTELLIGENCE',
      'BUSINESS PROCESS AUTOMATION',
      'HR',
    ],
  },
];

const onPremisePricingPlans = [
  {
    name: 'Business Plan (50 Users)',
    price: '13,185 AED',
    description: 'Self-hosted version for effective collaboration',
    users: '50 Users',
    features: ['Full Control Over Data', 'Source Code Access', 'Integration Options', 'Helpdesk', 'E-learning'],
  },
  {
    name: 'Business Plan (100 Users)',
    price: '21,998 AED',
    description: 'Self-hosted version for growing teams',
    users: '100 Users',
    features: ['Full Control Over Data', 'Source Code Access', 'Integration Options', 'Helpdesk', 'E-learning'],
  },
  {
    name: 'Business Plan (250 Users)',
    price: '40,361 AED',
    description: 'Ideal for mid-sized businesses',
    users: '250 Users',
    features: ['Full Control Over Data', 'Source Code Access', 'Integration Options', 'Helpdesk', 'E-learning'],
  },
  {
    name: 'Business Plan (500 Users)',
    price: '66,070 AED',
    description: 'Perfect for large organizations',
    users: '500 Users',
    features: ['Full Control Over Data', 'Source Code Access', 'Integration Options', 'Helpdesk', 'E-learning'],
  },
  {
    name: 'Enterprise Plan (1000 Users)',
    price: '91,770 AED',
    description: 'Tailored for large enterprises',
    users: '1000 Users',
    features: ['Full Control Over Data', 'Source Code Access', 'Integration Options', 'Helpdesk', 'E-learning'],
  },
];

const Bitrix = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="relative isolate overflow-hidden py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-5xl font-semibold tracking-tight text-blue-900 sm:text-7xl">Bitrix CRM Solution</h2>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-600 sm:text-xl/8">
              Bitrix24 CRM is an all-in-one platform designed to empower businesses to manage every aspect of their customer relationships effectively.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-gray-800 sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse gap-1">
                  <dt className="text-base/7 text-gray-900">{stat.name}</dt>
                  <dd className="text-4xl font-semibold tracking-tight text-blue-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* Cloud Pricing Section */}
      <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-indigo-600">Cloud Pricing</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
            Choose the right Cloud plan for you
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
          Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.
        </p>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:max-w-4xl lg:grid-cols-3 gap-x-6">
          {cloudPricingPlans.map((plan) => (
            <div key={plan.name} className="rounded-3xl border p-8 ring-1 ring-gray-900/10">
              <h3 className="text-base/7 font-semibold text-indigo-600">{plan.name}</h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight">{plan.price}</span>
                <span className="text-base">/month</span>
              </p>
              <p className="mt-4 text-gray-600">{plan.description}</p>
              <p className="mt-4 text-gray-500">{plan.users}</p>
              <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" />
                    {feature}
                  </li>
                ))}
              </ul>
              <a
                href="#"
                className="mt-8 block rounded-md bg-indigo-500 text-white text-center px-3.5 py-2.5 text-sm font-semibold hover:bg-indigo-400"
              >
                Choose The Package
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* On-Premise Pricing Section */}
      <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-indigo-600">On-Premise Pricing</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
            Choose the right On-Premise plan for you
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
          Choose a powerful self-hosted plan that provides full control over your data and additional tools for effective collaboration.
        </p>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:max-w-4xl lg:grid-cols-3 gap-x-6">
          {onPremisePricingPlans.map((plan) => (
            <div key={plan.name} className="rounded-3xl border p-8 ring-1 ring-gray-900/10">
              <h3 className="text-base/7 font-semibold text-indigo-600">{plan.name}</h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight">{plan.price}</span>
                
              </p>
              <p className="mt-4 text-gray-600">{plan.description}</p>
              <p className="mt-4 text-gray-500">{plan.users}</p>
              <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" />
                    {feature}
                  </li>
                ))}
              </ul>
              <a
                href="#"
                className="mt-8 block rounded-md bg-indigo-500 text-white text-center px-3.5 py-2.5 text-sm font-semibold hover:bg-indigo-400"
              >
                Choose The Package
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Bitrix;
